import { useApi } from '~/composables/useApi';

export const useFtGetCategoryPrice = () => {
  const { query } = useApi();

  const getCategoryPrice:any = async (categoty_id) =>{
    const myGraphqlQuery = `
      query {
        getCategoryPrice(category_id: "${categoty_id}"){
          min_price
          max_price
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getCategoryPrice,
  }
}


export default useFtGetCategoryPrice;