<template>
  <div class="sf-accordion-item">
    <slot
      name="header"
      v-bind="{
        header,
        isOpen,
        accordionClick,
        showChevron: $parent.showChevron,
      }"
    >
      <SfButton
        :aria-pressed="isOpen.toString()"
        :aria-expanded="isOpen.toString()"
        :class="{ 'is-open': isOpen }"
        class="sf-button--pure sf-accordion-item__header"
        :data-testid="'accordion-item-' + header"
        @click="accordionClick"
      >
        {{ header }}
        <slot name="additional-info" />
        <SfChevron
          tabindex="0"
          class="sf-accordion-item__chevron"
          :class="{ 'sf-chevron--right': !isOpen }"
        />
      </SfButton>
      <span class="filters__selected">
        {{ getSelectedSorting }}
      </span>
    </slot>
    <SfTransition :transition="$parent.transition">
      <div v-if="isOpen">
        <div class="sf-accordion-item__content">
          <slot />
        </div>
      </div>
    </SfTransition>
  </div>
</template>
<script>
import { focus } from "@storefront-ui/vue/src/utilities/directives";
import SfTransition from "@storefront-ui/vue/src/utilities/transitions/component/SfTransition";
import SfChevron from "@storefront-ui/vue/src/components/atoms/SfChevron/SfChevron.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
export default {
  name: "FtAccordionItem",
  directives: { focus },
  components: {
    SfChevron,
    SfButton,
    SfTransition,
  },
  props: {
    header: {
      type: String,
      default: "",
    },
    sorting_selected: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    accordionClick() {
      this.$parent.$emit("toggle", this._uid);
    },
  },
  computed: {
    getSelectedSorting() {
      let selected_label = this.sorting_selected.options.find(
        ({ value }) => value === this.sorting_selected.selected
      );
      return selected_label.label;
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfAccordion.scss";
</style>
