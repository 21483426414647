import { useApi } from '~/composables/useApi';
import { Aggregation, Products } from '~/modules/GraphQL/types';
import GetProductFilterBySearchQuery from '~/modules/catalog/category/components/filters/command/getProductFilterBySearch.gql';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export const getProductFilterBySearchCommand = {
  execute: async (categoryIdFilter:GetProductSearchParams): Promise<Aggregation[]> => {
    const { query } = useApi();
    const { data } = await query<{ products: Products }>(GetProductFilterBySearchQuery,  categoryIdFilter );

    return data?.products?.aggregations ?? [];
  },
};

export default getProductFilterBySearchCommand;
